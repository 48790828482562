
    import Vue from 'vue';
    import mixins from 'vue-typed-mixins';
    import * as constants from '@/tsfiles/constants';
    import VueConstants from '@/components/VueConstants';
    import { signout } from '@/tsfiles/firebase';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import { RoleUtils } from '@/tsfiles/roleutils';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import * as analytics from '@/tsfiles/analytics';
    import { PageMessage } from '@/tsfiles/interfaces';
    import Avatar from '@/components/Avatar.vue';
    import DeleteUserModal from '@/components/user/DeleteUserModal.vue';
    import { UserService, AdminService, DatingService, SharedConstants } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'HeaderMenu',

        components: {
            'url-avatar': Avatar,
            'delete-modal': DeleteUserModal,
        },

        props: {},

        data() {
            return {
                showDeleteAccountModal: false,
                deleteInputStr: '',
                disableImpersonate: false,
                showDateRemindersModal: false,
            };
        },

        watch: {
            //
            // Disable 'impersonate' if not on a user's home page.  We want to show the
            // item if super admin, but make it disabled if we cannot actually impersonate (not
            // on a user's page).
            //
            '$route.params.userId': {
                immediate: true,
                deep: true,
                handler(newVal: string, oldVal: string) {
                    if (newVal && newVal !== oldVal) {
                        this.disableImpersonate = this.$store.getters.isAuthedUserId(Number(newVal));
                    }
                },
            },
        },

        computed: {
            showSigninAs(): boolean {
                return (
                    this.$store.getters.isSignedIn &&
                    (RoleUtils.IsServerSuperAdmin() || this.$store.getters.getAdminUserId) &&
                    !this.$store.getters.isAuthedUserId(this.$store.getters.getAdminUserId)
                );
            },

            showRevert(): boolean {
                return this.$store.getters.isSignedIn && this.$store.getters.getAdminUserId;
            },

            showSupportAdminMenu(): boolean {
                return RoleUtils.CanSupportUsers();
            },

            showServerAdminMenu(): boolean {
                return RoleUtils.IsServerSuperAdmin();
            },

            showAdminMenus(): boolean {
                return this.showSupportAdminMenu || this.showServerAdminMenu;
            },
        },

        methods: {
            gotoPage(routeToPage: string): void {
                if (this.$router.currentRoute.name !== routeToPage) {
                    this.$router.push({
                        name: routeToPage,
                        params: { userId: this.$store.getters.getAuthedUserId },
                    });
                }
            },

            //
            // Normal signout means we want to signout from firebase and our server; however,
            // if coming from a deleteUser call, we don't want to signout from the server since
            // there's no user anymore.  We do need all other signouts to happen though.
            //
            doSignout(signoutFromServer: boolean) {
                signout(signoutFromServer, true);
            },

            //
            // Deleting a user account will also do a sign out on the server, clearing
            // the context.
            //
            // NOTE: This does not delete the firebase account.  The server doesn't
            // either...
            //
            // If a test-admin, the server will actually do a hard-delete.
            //
            async okToDelete(doHardDelete: boolean) {
                try {
                    if (doHardDelete && this.$store.getters.isTestAdmin) {
                        await ApiUtils.apiWrapper(AdminService.hardDeleteUser);
                    } else {
                        await ApiUtils.apiWrapper(UserService.deleteUser);
                    }

                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_DELETE_USER);
                    this.doSignout(false);
                } catch (error: any) {
                    if (error.response.status === 409 && error.response.data.message === SharedConstants.WARNING_RESOURCE_BLOCKED) {
                        alert('Your account cannot be deleted.  Please contact support for help.');
                    } else {
                        Utils.CommonErrorHandler(error);
                    }
                }

                this.showDeleteAccountModal = false;
            },

            async confirmSendDateReminders() {
                try {
                    await ApiUtils.apiWrapper(AdminService.sendDateReminderNotifications);
                    alert('Date reminders have been sent successfully.');
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async resetDating() {
                try {
                    await ApiUtils.apiWrapper(DatingService.wipeAllData);
                    alert('All your dating data has been reset, which may affect other users (e.g. likes, matches).');
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async resetProfileSharing() {
                try {
                    await ApiUtils.apiWrapper(UserService.resetProfileSharing);
                    alert('Your profile sharing has been reset.');
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // Get current user we are sitting on, which is the way to impersonate.
            // Impersonation is done in MainApp.  Just pass back the userId.  It will
            // eventually get to MainApp.
            //
            impersonateUser() {
                const userId = this.$router.currentRoute.params.userId;
                if (userId && !this.$store.getters.isAuthedUserId(userId)) {
                    this.$emit('impersonate-user', userId);

                    // Disable impersonation.  It will get re-enabled if going
                    // to another user's page, or if reverting back to admin.
                    this.disableImpersonate = true;
                }
            },

            //
            // Tell parent to impersonate the admin, which basically just makes the
            // admin the new user.
            //
            revertToAdmin() {
                this.$emit('impersonate-user', this.$store.getters.getAdminUserId);
            },
        },
    });
