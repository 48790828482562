
    import Vue from 'vue';
    import * as constants from '@/tsfiles/constants';
    import EventBus from '@/eventbus';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import DatingCalendarRow from '@/components/dating/DatingCalendarRow.vue';
    import {
        SharedConstants,
        DatingService,
        GetCalendarResponse,
        SaveCalendarRequest,
        CalendarEntry,
        Match,
        SendAvailabilityRequest,
        SendAvailabilityResponse,
    } from '@bostonventurestudio/lola-api';

    export default Vue.extend({
        name: 'DatingMatchCalendarModel',

        components: {
            'dating-row': DatingCalendarRow,
        },

        props: {
            match: {
                type: Object as () => Match,
                default: () => ({} as Match),
            },
        },

        data() {
            return {
                dates: [] as CalendarEntry[],
                partnerAvailableDays: [] as CalendarEntry[],
                showAvailabilitySentAlert: false,
                showDateConfirmedAlert: false,
                suggestDates: false,
                matchConfirmed: false,
            };
        },

        watch: {},

        mounted() {
            EventBus.$on(SharedConstants.NOTIFICATION_DATE_UNMATCHED, this.fetchCalendar);
            EventBus.$on(SharedConstants.NOTIFICATION_DATE_RESCHEDULED, this.fetchCalendar);
            EventBus.$on(SharedConstants.NOTIFICATION_NEW_DATE_MATCH, this.fetchCalendar);
            EventBus.$on(SharedConstants.NOTIFICATION_NEW_PENDING_DATE_MATCH, this.fetchCalendar);

            this.fetchCalendar();
        },

        beforeDestroy() {
            EventBus.$off(SharedConstants.NOTIFICATION_DATE_UNMATCHED, this.fetchCalendar);
            EventBus.$off(SharedConstants.NOTIFICATION_DATE_RESCHEDULED, this.fetchCalendar);
            EventBus.$off(SharedConstants.NOTIFICATION_NEW_DATE_MATCH, this.fetchCalendar);
            EventBus.$off(SharedConstants.NOTIFICATION_NEW_PENDING_DATE_MATCH, this.fetchCalendar);
        },

        computed: {
            showViewProspectsButton(): boolean {
                for (const item of this.dates) {
                    if (item.available) {
                        return true;
                    }
                }

                return false;
            },
            partnerIsAvailableMessage(): string {
                return this.match.partnerName + ' is available';
            },
        },

        methods: {
            async fetchCalendar() {
                try {
                    var query = {};
                    if (this.match?.matchId ?? 0 > 0) {
                        query = { matchId: this.match?.matchId };
                    }
                    const ret = (await ApiUtils.apiWrapper(DatingService.getCalendar, query)) as GetCalendarResponse;
                    if (ret && ret.entries && ret.entries.length > 0) {
                        this.setDates(ret.entries);
                    } else {
                        this.dates = [] as CalendarEntry[];
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            rowSelected(idx: number) {
                if (idx < 0 || idx > this.dates.length) {
                    logInvalidParams(this.$options.name, 'rowSelected');
                    return;
                }

                const item = this.dates[idx];
                if (item.match) {
                    if (!item.match.matchId || !item.match.partnerUserId || item.match.partnerUserId <= 0) {
                        logInvalidParams(this.$options.name, 'rowSelected - invalid partnerUserId');
                        return;
                    }

                    const matchId = item.match.matchId.toString();
                    this.$router.push({
                        name: constants.ROUTE_USER_DATE,
                        query: { matchId },
                    });
                } else if (!item.readOnly) {
                    this.checkboxToggled(idx);
                }
            },

            async checkboxToggled(idx: number) {
                if (idx < 0 || idx >= this.dates.length) {
                    logInvalidParams(this.$options.name, 'checkboxToggled');
                    return;
                }
                this.dates[idx].available = !this.dates[idx].available;
            },
            setDates(dates: CalendarEntry[]) {
                var partnerAvailableDays = [] as CalendarEntry[];
                for (const item of dates) {
                    if (item.partnerAvailable) {
                        partnerAvailableDays.push(item);
                    }
                }
                Vue.set(this, 'partnerAvailableDays', partnerAvailableDays);
                Vue.set(this, 'dates', dates);
            },
            async sendAvailability() {
                this.showDateConfirmedAlert = false;
                this.showAvailabilitySentAlert = false;
                if (this.match.matchId == 0) {
                    logInvalidParams(this.$options.name, 'sendAvailability', 'match is required');
                    return;
                }
                try {
                    var query = { matchId: this.match.matchId, entries: this.dates } as SendAvailabilityRequest;
                    const ret = (await ApiUtils.apiWrapper(DatingService.sendAvailability, query)) as SendAvailabilityResponse;

                    // Replace with the response
                    if (ret.match && ret.match.matchId) {
                        if (ret.match.date) {
                            this.matchConfirmed = true;
                            this.showDateConfirmedAlert = true;
                            this.fetchCalendar();
                        } else {
                            this.showAvailabilitySentAlert = true;
                        }
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
            navigateToChat() {
                this.$router.push({
                    name: constants.ROUTE_USER_DATE,
                    query: { matchId: this.match?.matchId?.toString() },
                });
            },
        },
    });
